<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-container fluid>
      <!--ボタン領域-->
      <v-row style="margin-left: 3px">
        <div class="search-row-exeBtn pt-2">
          <!-- 請求内容 -->
          <div style="float: left">
            <!-- 閉じるボタン-->
            <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_close')">
              {{ $t("btn.btn_close") }}
            </v-btn>
          </div>
          <div style="float: right">
            <!-- 設定ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_config')"
              >{{ $t("btn.btn_config") }}</v-btn
            >
          </div>
        </div>
      </v-row>
    </v-container>

    <!-- 入力フォーム -->
    <v-form ref="editList" lazy-validation>
      <!-- コンテナ -->
      <v-container fluid>
        <!-- データテーブル -->
        <!-- ヘッダーを固定(fixed-header) -->
        <!-- ヘッダーバインディング(headerItems) -->
        <!-- アイテムバインディング(inputList) -->
        <!-- 波紋エフェクトの無効化(ripple) -->
        <!-- フィルタリングの無効化(disable-filtering) -->
        <!-- ページングの無効化(disable-pagination) -->
        <!-- デフォルトフッターの非表示(hide-default-footer) -->
        <!-- 高さの指定(height) -->
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          height="150px"
        >
          <!-- ロケグループ -->
          <template v-slot:[`item.locationGroup`]="{ item }">
            <!-- 編集可能な場合 -->
            <span>
              <!-- オートコンプリート -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- 入力バインディング(item.locationGroup) -->
              <!-- 入力候補(locationGroupList) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <v-autocomplete
                dense
                outlined
                class="text-box"
                v-model="item.locationGroup"
                :items="locationGroupList"
                autocomplete="off"
                :rules="[rules.inputRequired]"
              >
                <!-- セレクトボックス -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </span>
          </template>

          <!-- ロケ -->
          <template v-slot:[`item.location`]="{ item }">
            <!-- 編集可能な場合 -->
            <span>
              <!-- オートコンプリート -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- 入力バインディング(item.location) -->
              <!-- 入力候補(locationList) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <v-autocomplete
                dense
                outlined
                class="text-box"
                v-model="item.locationSid"
                :items="locationList"
                :search-input.sync="locationSearchTxt"
                @change="changeLocation"
                autocomplete="off"
                :rules="[rules.inputRequired]"
              >
                <!-- セレクトボックス -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </span>
          </template>

          <!-- 品番 -->
          <template v-slot:[`item.itemCd`]="{ item }">
            <!-- 編集可能な場合 -->
            <span>
              <!-- オートコンプリート -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- 入力バインディング(item.itemStandardSid) -->
              <!-- 入力候補(itemList) -->
              <!-- 変更時イベント(changeProductNm) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <v-autocomplete
                dense
                outlined
                class="text-box"
                v-model="item.itemStandardSid"
                :items="itemList"
                @change="(event) => changeProductNm(event, item)"
                :search-input.sync="searchItem"
                persistent-hint
                :error-messages="item.requiredItemCheckMsg"
                :hint="setItemCdChange()"
                autocomplete="off"
                :rules="[rules.inputRequired]"
              >
                <!-- セレクトボックス -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </span>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lotNo`]="{ item, index }">
            <!-- 編集可能な場合 -->
            <span>
              <!-- テキストフィールド -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(item.lotNo) -->
              <!-- 削除ボタンを表示(clearable) -->
              <!-- 数値チェック(rules.isNumber) -->
              <!-- 整数チェック(rules.isInteger) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <!-- 最大桁数チェック(limitNumLength) -->
              <!-- 参照IDを割り当て('lotNo' + index) -->
              <v-text-field
                outlined
                dense
                v-model="item.lotNo"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                maxlength="20"
                :ref="'lotNo' + index"
                :disabled="item.approvalFlg == 1 || item.lotFlg == '0'"
                autocomplete="off"
              />
            </span>
          </template>
          <!-- S/N -->
          <template v-slot:[`item.serial`]="{ item, index }">
            <!-- 編集可能な場合 -->
            <span>
              <!-- テキストフィールド -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(item.lotNo) -->
              <!-- 削除ボタンを表示(clearable) -->
              <!-- 数値チェック(rules.isNumber) -->
              <!-- 整数チェック(rules.isInteger) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <!-- 最大桁数チェック(limitNumLength) -->
              <!-- 参照IDを割り当て('lotNo' + index) -->
              <v-text-field
                outlined
                dense
                v-model="item.serial"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                maxlength="32"
                :ref="'serial' + index"
                :disabled="item.approvalFlg == 1 || item.serialFlg == '0'"
                autocomplete="off"
              />
            </span>
          </template>
          <!-- 期限日 -->
          <template v-slot:[`item.dueDate`]="{ item }">
            <div id="table-lot">
              <v-text-field
                class="text-box"
                outlined
                dense
                v-model="item.dueDate"
                maxlength="8"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.isNumber]"
                :disabled="item.approvalFlg == 1 || item.dueDateFlg == '0'"
              />
            </div>
          </template>
          <!-- 棚卸数 -->
          <template v-slot:[`item.countQty`]="{ item, value, index }">
            <!-- テキストフィールド -->
            <!-- アウトラインデザイン(outlined) -->
            <!-- フィールドの高さを減らす(dense) -->
            <!-- 入力バインディング(item.countQty) -->
            <!-- 削除ボタンを表示(clearable) -->
            <!-- 数値チェック(rules.isNumber) -->
            <!-- 整数チェック(rules.isInteger) -->
            <!-- 必須チェック(rules.inputRequired) -->
            <!-- 最大桁数チェック(limitNumLength) -->
            <!-- 変更時イベント(commaCountQty) -->
            <!-- クリック時イベント(clickPop) -->
            <!-- 参照IDを割り当て('countQty' + index) -->
            <v-text-field
              outlined
              dense
              v-model="item.countQty"
              :id="'countQty' + index"
              class="input-number"
              clear-icon="mdi-close-circle"
              clearable
              @change="commaCountQty(item, index)"
              @click="clickPop(item.countQty)"
              :ref="'countQty' + index"
              :disabled="item.approvalFlg == 1"
              autocomplete="off"
            />
          </template>

          <!-- 担当者 -->
          <template v-slot:[`item.person`]="{ item }">
            <!-- オートコンプリート -->
            <!-- フィールドの高さを減らす(dense) -->
            <!-- アウトラインデザイン(outlined) -->
            <!-- 入力バインディング(item.person) -->
            <!-- 入力候補(userList) -->
            <!-- 最大桁数の設定(maxlength) -->
            <!-- 必須チェック(rules.inputRequired) -->
            <v-autocomplete
              dense
              outlined
              class="text-box"
              v-model="item.person"
              :items="userList"
              maxlength="10"
              :disabled="item.approvalFlg == 1"
              :error-messages="item.requiredCheckMsg"
              autocomplete="off"
            >
              <!-- セレクトボックス -->
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- 付属キー１ -->
          <template v-slot:[`item.lotSubkey1`]="{ item }">
            <div id="table-lot">
              <v-text-field
                class="text-box"
                outlined
                dense
                v-model="item.lotSubkey1"
                maxlength="20"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="item.approvalFlg == 1 || item.lotSubkey1Flg == '0'"
              />
            </div>
          </template>
          <!-- 付属キー２ -->
          <template v-slot:[`item.lotSubkey2`]="{ item }">
            <div id="table-lot">
              <v-text-field
                class="text-box"
                outlined
                dense
                v-model="item.lotSubkey2"
                maxlength="20"
                clear-icon="mdi-close-circle"
                clearable
                :disabled="item.approvalFlg == 1 || item.lotSubkey2Flg == '0'"
              />
            </div>
          </template>
        </v-data-table>
      </v-container>
    </v-form>
    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading"; // ローディング画面
import { getParameter } from "../../assets/scripts/js/GetParameter"; // マスタ取得
import ConfirmDialog from "../../components/ConfirmDialog"; // 確認ダイアログ
import SimpleDialog from "@/components/SimpleDialog"; // シンプルダイアログ
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper"; // 日時ヘルパー
import { i18n } from "../../lang/lang.js"; // 多言語対応
import { commonUtil } from "../../assets/scripts/js/CommonUtil"; // 共通ユーティリティ
import { appConfig } from "../../assets/scripts/js/AppConfig"; // アプリ設定
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil"; // メッセージユーティリティ

export default {
  // 表示名
  name: "CountAchieveAddDialog",

  // コンポーネント
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
  },

  // プロパティ
  props: {
    isMenu: { type: Boolean, default: false },
    viewDataList: { type: Array, default: null },
    locationGroupList: { type: Array, default: null },
    _locationList: { type: Array, default: null },
    isOpenDetailDialog: { type: Boolean, default: false },
    suppliers: { type: String, default: null },
    inventoryDate: { type: String, default: null },
    warehouseSid: { type: String, default: null },
    stockCheckNoPara: { type: String, default: null },
    stockMethodKbnPara: { type: String, default: null },
  },

  // データ
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 倉庫名
    warehouseName: "",
    // APIメッセージ確認ダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      sixPageFlag: false,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
    },
    // 確認メッセージダイアログ
    approvalDialog: {
      completeDialogMsg: false,
      completeMessage: "",
    },
    // 登録ボタン押下時確認メッセージ
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // 担当者リスト
    userList: [],
    // 品番リスト
    itemList: [],
    searchItem: "",
    // エラーメッセージ
    requiredCheckMsg: "",
    locationList: [],
    // 取引先
    suppliersSelected: "",
    // 棚卸指示No
    stockCheckNo: "",
    // 棚卸方式
    stockMethodKbn: "",
    // ヘッダー項目
    headerItems: [
      // ロケグループ
      {
        text: i18n.tc("label.lbl_locaGroup"),
        value: "locationGroup",
        width: "7%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "5%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 品番
      {
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "7%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot"),
        value: "lotNo",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // S/N
      {
        text: i18n.tc("label.lbl_serial"),
        value: "serial",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 期限日
      { text: i18n.tc("label.lbl_dueDate"), value: "dueDate", width: "5%", align: "left" },
      // 棚卸数
      {
        text: i18n.tc("label.lbl_countQty"),
        value: "countQty",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 担当者
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "person",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 付属キー１
      {
        text: i18n.tc("label.lbl_lotSubkey1"),
        value: "lotSubkey1",
        width: "5%",
        align: "center",
      },
      // 付属キー２
      {
        text: i18n.tc("label.lbl_lotSubkey2"),
        value: "lotSubkey2",
        width: "5%",
        align: "center",
      },
    ],
    // 必須項目未入力エラーメッセージ
    requiredItemCheckMsg: "",
    // 一覧表示リスト
    inputList: [],
    // 修正削除用リスト
    editDeleteList: [],
    // 明細追加情報
    defaultItem: {
      locationGroup: "", // ロケグループ
      location: "", // ロケ
      itemCd: "", // 品番
      itemStandardSid: "", // 商品標準SID
      stockQty: "", // 在庫数
      countQty: "", // 棚卸数
      person: "", // 担当者
      delete: "", // 削除ボタン
      displayFlg: true, // 編集可能フラグ
      locationSid: "", // ロケーションSID
      lotNo: "", // ロット
      lotFlg: "0", //ロットフラグ
      serial: "", // S/N
      serialFlg: "0", // S/Nフラグ
      dueDate: "", // 期限日
      dueDateFlg: "0", // 期限日フラグ
      lotSubkey1: "", // 付属キー１
      lotSubkey1Flg: "0", // 付属キー１フラグ
      lotSubkey2: "", // 付属キー２
      lotSubkey2Flg: "0", // 付属キー２フラグ
      stockCheckDiv: "", // 棚卸方式区分
      stockCheckName: "", // 棚卸方式名
      stockCheckSid: "", // 棚卸指示SID
      stockCheckNo: "", // 棚卸計画No
      stockResultSid: null, // 棚卸計画No
      userName: "", // 担当者名
      userSid: "", // 担当者SID
      warehouseSid: "", // 倉庫SID
      freeCostDiv: "", // 有償無償区分
      yyyymmddHhmmss: "", // 日時
      addItemCdFlg: "01", // 行追加フラグ
      deleteFlg: "0", // 削除フラグ
    },
    locationSearchTxt: "",
    locationHint: "",
    // バリデーション
    rules: {
      // 必須チェック
      inputRequired: (value) =>
        (!!value && value.toString().replace(/\s+/g, "") !== "") || i18n.tc("check.chk_input"),
    },
  }),

  // メソッド
  methods: {
    // 初期表示処理
    init() {
      // リストを初期化
      this.inputList = [];
      this.itemList = [];
      this.locationList = [];
      // 担当者取得
      this.getUsers();
      // 前画面から受け取るパラメータ
      this.suppliersSelected = this.suppliers; // 取引先
      this.stockCheckNo = this.stockCheckNoPara; // 棚卸指示No
      this.stockMethodKbn = this.stockMethodKbnPara; // 棚卸方式
      this.inputList.push(Object.assign({}, this.defaultItem));
      this.$refs.editList.resetValidation();
    },

    // ユーザマスタGET
    getUsers() {
      // ローディング画面を表示します。
      // this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // ★必須パラメータ
      config.params.searchCategoryDiv = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getUsers() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                  name: row.userName,
                  userId: row.userId, // ユーザーID
                });
              });
              this.userList = list;

              // 取得したユーザーリストの中から、明細のユーザーSidと一致するものを探す
              for (var i = 0; i < this.viewDataList.length; i++) {
                for (var ii = 0; ii < this.userList.length; ii++) {
                  if (this.viewDataList[i].userSid == this.userList[ii].value) {
                    // inputListの担当者（person）に一致したユーザーSidを入れる
                    this.viewDataList[i].person = this.userList[ii].value;
                  }
                }
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面を非表示にします。
            // this.loadingCounter = 0;
          });
      });
    },

    // ロケグループのプルダウン値をセット
    filterGroup() {
      var locationFilter = this.locationList.filter((e, index, arry) => {
        return arry.findIndex((el) => e.group == el.group) === index;
      });
      return locationFilter;
    },

    // 棚卸カンマ区切り処理
    commaCountQty(val) {
      var comma = commonUtil.zen2han(val.countQty).replaceAll(",", "");
      comma = comma.replace(/[^0-9]/gi, "");
      if (comma.length > 9) {
        comma = comma.substring(0, 9);
      }
      if (!isNaN(Number(comma))) {
        val.countQty = commonUtil.formatToCurrency(Number(comma));
        if (comma != null && comma != "") {
          // 棚卸数が入力されている場合
          val.difference = commonUtil.formatToCurrency(
            Math.ceil(comma - val.stockQty.replaceAll(",", ""))
          );
          if (val.difference.replaceAll(",", "") < 0) {
            val.colorFlg = true;
          } else {
            val.colorFlg = false;
          }
        } else {
          val.difference = commonUtil.formatToCurrency(
            Math.ceil(val.countQty - val.stockQty.replaceAll(",", ""))
          );
          if (val.difference.replaceAll(",", "") < 0) {
            val.colorFlg = true;
          } else {
            val.colorFlg = false;
          }
        }
      }
    },
    /**
     * 入荷予定登録画面：入力カンマ処理 ケース数
     */
    changeDataStock(val) {
      var difference = commonUtil.zen2han(val.difference).replaceAll(",", "");
      difference = difference.replace(/[^0-9]/gi, "");
      if (difference.length > 9) {
        difference = difference.substring(0, 9);
      }
      if (!isNaN(Number(difference))) {
        val.difference = commonUtil.formatToCurrency(Number(difference));
      }
    },

    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let message;
      let action;
      switch (button) {
        case "btn_config": {
          if (!this.$refs.editList.validate()) {
            return;
          }
          const duplicationData = this.viewDataList.find(
            (o) =>
              o.locationSid == this.inputList[0].locationSid &&
              o.location === this.inputList[0].location &&
              o.locationGroup == this.inputList[0].locationGroup &&
              o.itemCd === this.inputList[0].itemCd.substring(0, 50).trim() &&
              o.itemStandardSid === this.inputList[0].itemStandardSid &&
              o.lotNo == this.inputList[0].lotNo &&
              o.serial == this.inputList[0].serial &&
              o.dueDate == this.inputList[0].dueDate &&
              o.lotSubkey1 == this.inputList[0].lotSubkey1 &&
              o.lotSubkey2 == this.inputList[0].lotSubkey2
          );
          if (duplicationData) {
            message = messsageUtil.getMessage("P-COM-001_006_C", [
              "既に存在するロケグループ、ロケ、品番、ロット／S/N、期限日／付属キー１／付属キー２がありますので棚卸数を加算",
            ]);
          } else {
            message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_config")]);
          }
          action = this.config;
          break;
        }
        case "btn_close":
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.close;
          break;
        default:
          message = "";
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 閉じる処理
     */
    close() {
      this.$emit("update:isOpenDetailDialog", false);
    },
    /**
     * 設定ボタン押下処理
     */
    config() {
      this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
        i18n.tc("btn.btn_config"),
      ]);
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.firstPageFlag = true;
      //品番と付帯を分ける
      this.inputList[0].itemCdShow = this.inputList[0].itemCd;
      this.inputList[0].incidental = this.inputList[0].itemCd.slice(-50).trim();
      this.inputList[0].itemCd = this.inputList[0].itemCd.substring(0, 50).trim();
      //ロット、S/N、期限日、付属キー１、付属キー２結合
      var lotShow = commonUtil.createLotNo(
        this.inputList[0].lotNo,
        this.inputList[0].serial,
        this.inputList[0].dueDate,
        this.inputList[0].lotSubkey1,
        this.inputList[0].lotSubkey2
      );
      this.inputList[0].lotNoShow = lotShow;
      // 棚卸実績登録画面表示用
      let lotObj = commonUtil.convertLotNo(lotShow);
      this.inputList[0].lotNo = lotObj.lot;
      this.inputList[0].dueDate = lotObj.dueDate;
      this.$emit("child-event", true, this.inputList[0]);
      this.close();
    },
    /**
     * 行追加：品番取得
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.itemList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.changedProductHint = val.text;
        this.searchItem = val.text;
        this.inputList[0].itemCd = val.code;
      } else {
        this.changedProductHint = "";
        this.searchItem = "";
        this.inputList[0].serialFlg = "0";
        this.inputList[0].lotFlg = "0";
        this.inputList[0].serial = "";
        this.inputList[0].lotNo = "";
        this.inputList[0].dueDate = "";
        this.inputList[0].dueDateFlg = "0";
        this.inputList[0].lotSubkey1 = "";
        this.inputList[0].lotSubkey1Flg = "0";
        this.inputList[0].lotSubkey2 = "";
        this.inputList[0].lotSubkey2Flg = "0";
      }
      this.itemList = this.itemList.filter((v) => v.value == value);
    },
    /**
     * 変更エリア：品番リストセット処理
     */
    setItemCdChange() {
      for (var i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].value == this.inputList[0].itemStandardSid) {
          this.inputList[0].serialFlg = this.itemList[i].serialFlg;
          this.inputList[0].lotFlg = this.itemList[i].lotFlg;
          this.inputList[0].dueDateFlg = this.itemList[i].dueDateFlg;
          this.inputList[0].lotSubkey1Flg = this.itemList[i].lotSubkey1Flg;
          this.inputList[0].lotSubkey2Flg = this.itemList[i].lotSubkey2Flg;
          return this.itemList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeLocation(value) {
      // 既存品番/品名を選択時
      let val = this.locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.locationHint = val.text;
        this.locationSearchTxt = val.text;
        this.inputList[0].location = val.text;
      }

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.locationHint = "";
      } else {
        // 処理なし
      }
    },
  },
  // 監視
  watch: {
    isOpenDetailDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        // 初期表示処理を実行
        this.init();
      }
    },

    dialog(val) {
      val || this.close();
    },
    // 行追加：品番検索
    searchItem(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.changedProductHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.itemList = list.concat(result[0]);
              this.itemList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.itemList.length = 0;
          this.itemList = [];
        }
      }
    },
    locationSearchTxt(val) {
      if (val ?? false) {
        let target = this.inputList[0];
        if (this.warehouseSid == null || this.warehouseSid == "") {
          this.locationList = [];
          target.location = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.locationHint) {
          return;
        }
        if (val.length >= appConfig.CNTCHARLOCATION) {
          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");
          config.params.locationNo = val;
          // "0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得
          config.params.searchCategoryDiv = "0";
          // 処理区分
          config.params.processDiv = "01";
          config.params.warehouseSid = this.warehouseSid;
          // 棚卸指示No
          config.params.stockCheckNo = this.stockCheckNo;
          // 棚卸方式
          config.params.stockCheckDiv = this.stockMethodKbn;
          // 参照時刻は棚卸日で設定
          config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.inventoryDate);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.MST_CNT_LOCATIONBIZ, config)
              .then((response) => {
                // console.debug("getArrivalList() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  const list = [];
                  jsonData.resIdv.locations.forEach((row) => {
                    if (target.locationGroup == row.locationGroup) {
                      list.push({
                        text: row.locationNo,
                        value: row.locationSid,
                        name: row.locationNo,
                      });
                    }
                  });
                  this.locationList = list;

                  // エラー時
                } else {
                  // エラーメッセージをpopupのmessageに格納
                  this.infoDialog.message = jsonData.resCom.resComMessageId
                    ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                    : jsonData.resCom.resComMessages[
                        "resComMessage" + sessionStorage.getItem("lang")
                      ];
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;
                }
                resolve(response);
              })
              .catch((resolve) => {
                console.error("getArrivalList() Resolve", resolve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else {
          this.locationList.length = 0;
          this.locationList = [];
        }
      }
    },
  },
  mounted() {
    // 初期表示処理を実行
    this.init();
  },
  // Vueインスタンスが生成された後
  created() {},
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}
</style>
