<!-- 棚卸実績登録画面 -->
<template>
  <!-- 背景色指定 -->
  <div style="background: white">
    <!-- ローディング画面 -->
    <Loading :loadingCounter="loadingCounter" />
    <!-- シート -->
    <v-sheet class="overflow-hidden">
      <!-- ローディング画面 -->
      <Loading />
      <!-- ナビゲーションバー -->
      <NavBar tittle="label.lbl_countAchieveList" />
      <!-- 入力フォーム -->
      <v-form ref="form">
        <!-- コンテナ -->
        <v-container fluid>
          <!-- 検索条件 -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item">
              <!-- 選択された取引先の名前が20文字より多い場合 -->
              <div v-if="clientName.length > 20">
                <!-- ツールチップを表示する -->
                <v-tooltip bottom>
                  <!-- ツールチップの活性化条件 -->
                  <template v-slot:activator="{ on, attrs }">
                    <!-- ツールチップのラップ対象 -->
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <!-- テキストフィールド -->
                      <!-- フィールドの高さを減らす(dense) -->
                      <!-- 入力バインディング(clientName) -->
                      <!-- ラベル指定(label.lbl_supplier) -->
                      <!-- 読み取り専用(readonly) -->
                      <!-- 永続的なヒント(persistent-hint) -->
                      <v-text-field
                        dense
                        id="get-supplier"
                        v-model="clientName"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single"
                        readonly
                        persistent-hint
                      ></v-text-field>
                    </div>
                  </template>
                  <!-- ツールチップの表示内容 -->
                  <span>{{ clientName }}</span>
                </v-tooltip>
              </div>
              <!-- 選択された取引先の名前が20文字以下の場合 -->
              <div v-else>
                <div>
                  <!-- テキストフィールド -->
                  <!-- フィールドの高さを減らす(dense) -->
                  <!-- 入力バインディング(clientName) -->
                  <!-- ラベル指定(label.lbl_supplier) -->
                  <!-- 読み取り専用(readonly) -->
                  <!-- 永続的なヒント(persistent-hint) -->
                  <v-text-field
                    dense
                    id="get-supplier"
                    v-model="clientName"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    readonly
                    persistent-hint
                  ></v-text-field>
                </div>
              </div>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 棚卸方式 -->
            <div class="search-autocomplete">
              <!-- テキストフィールド -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(inventoryMethodSelectedName) -->
              <!-- ラベル指定(label.lbl_invatoryMethod) -->
              <!-- 永続的なヒント(persistent-hint) -->
              <!-- 読み取り専用(readonly) -->
              <v-text-field
                dense
                v-model="inventoryMethodSelectedName"
                :label="$t('label.lbl_invatoryMethod')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 棚卸日 -->
            <div class="date-style">
              <!-- テキストフィールド -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(inventoryDate) -->
              <!-- ラベル指定(label.lbl_invatoryDate) -->
              <!-- 読み取り専用(readonly) -->
              <v-text-field
                dense
                v-model="inventoryDate"
                :label="$t('label.lbl_invatoryDate')"
                class="txt-single date-style"
                readonly
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 倉庫 -->
            <div class="serch-textbox-arrivalNo">
              <!-- テキストフィールド -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(warehouseName) -->
              <!-- ラベル指定(label.lbl_warehouse) -->
              <!-- 読み取り専用(readonly) -->
              <v-text-field
                dense
                v-model="warehouseName"
                :label="$t('label.lbl_warehouse')"
                class="txt-single"
                readonly
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 棚卸計画No -->
            <div class="serch-textbox-arrivalNo">
              <!-- テキストフィールド -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(stockCheckNo) -->
              <!-- ラベル指定(label.lbl_inventoryPlanNo) -->
              <!-- 読み取り専用(readonly) -->
              <v-text-field
                dense
                v-model="stockCheckNo"
                :label="$t('label.lbl_inventoryPlanNo')"
                class="txt-single"
                readonly
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 追加条件 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="optionsSelect"
                :items="optionsList"
                :label="$t('label.lbl_options')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <!-- ボタン -->
          <v-row>
            <div class="search-row-exeBtn">
              <!-- 左寄せ -->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" class="api-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>

              <!-- 右寄せ -->
              <div class="btn-area">
                <!-- 新規明細追加ボタン -->
                <v-btn
                  color="primary"
                  class="api-btn"
                  @click="newDetailAdd"
                  :disabled="disableBtnFlg || approvalFlg || completeFlg"
                  >{{ $t("btn.btn_add") }}</v-btn
                >
                <!-- 完了ボタン -->
                <v-btn
                  color="primary"
                  class="api-btn"
                  :disabled="approvalFlg"
                  @click="completePush"
                  >{{ $t("btn.btn_complete") }}</v-btn
                >
                <!-- 登録ボタン -->
                <v-btn color="primary" class="api-btn" :disabled="approvalFlg" @click="actualAdd">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- 入力フォーム -->
      <v-form ref="editList" lazy-validation>
        <!-- コンテナ -->
        <v-container fluid>
          <!-- データテーブル -->
          <!-- ヘッダーを固定(fixed-header) -->
          <!-- ヘッダーバインディング(headerItems) -->
          <!-- アイテムバインディング(inputList) -->
          <!-- 波紋エフェクトの無効化(ripple) -->
          <!-- フィルタリングの無効化(disable-filtering) -->
          <!-- ページングの無効化(disable-pagination) -->
          <!-- デフォルトフッターの非表示(hide-default-footer) -->
          <!-- 高さの指定(height) -->
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :ripple="false"
            disable-filtering
            :hide-default-footer="true"
            height="650px"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :options.sync="sortOptions"
          >
            <!-- ロット -->
            <template v-slot:[`header.lotNo`]="{ header }">
              <span>
                {{ header.text }}<br />
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </template>

            <!-- ロケグループ -->
            <template v-slot:[`item.locationGroup`]="{ item }">
              <!-- 編集可能な場合 -->
              <span v-if="item.displayFlg && item.addItemCdFlg != '01'">
                <!-- オートコンプリート -->
                <!-- フィールドの高さを減らす(dense) -->
                <!-- アウトラインデザイン(outlined) -->
                <!-- 入力バインディング(item.locationGroup) -->
                <!-- 入力候補(locationGroupList) -->
                <!-- 必須チェック(rules.inputRequired) -->
                <v-autocomplete
                  dense
                  outlined
                  class="text-box"
                  v-model="item.locationGroup"
                  :items="locationGroupList"
                  :rules="[rules.inputRequired]"
                >
                  <!-- セレクトボックス -->
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </span>
              <!-- 編集不可の場合 -->
              <span v-else>
                <!-- ロケグループを表示 -->
                {{ $t("label.lbl_locaGroup") }} {{ item.locationGroup }}
              </span>
            </template>

            <!-- ロケ -->
            <template v-slot:[`item.location`]="{ item }">
              <!-- 編集可能な場合 -->
              <span v-if="item.displayFlg && item.addItemCdFlg != '01'">
                <!-- オートコンプリート -->
                <!-- フィールドの高さを減らす(dense) -->
                <!-- アウトラインデザイン(outlined) -->
                <!-- 入力バインディング(item.location) -->
                <!-- 入力候補(locationList) -->
                <!-- 必須チェック(rules.inputRequired) -->
                <v-autocomplete
                  dense
                  outlined
                  class="text-box"
                  v-model="item.location"
                  :items="locationList"
                  :rules="[rules.inputRequired]"
                >
                  <!-- セレクトボックス -->
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </span>
              <!-- 編集不可の場合 -->
              <span v-else>
                <!-- ロケを表示 -->
                {{ item.location }}
              </span>
            </template>

            <!-- 品番 -->
            <template v-slot:[`item.itemCd`]="{ item, index }">
              <!-- 編集可能な場合 -->
              <span v-if="item.displayFlg && item.addItemCdFlg != '01'">
                <!-- オートコンプリート -->
                <!-- フィールドの高さを減らす(dense) -->
                <!-- アウトラインデザイン(outlined) -->
                <!-- 入力バインディング(item.itemStandardSid) -->
                <!-- 入力候補(itemList) -->
                <!-- 変更時イベント(setStockQty) -->
                <!-- 必須チェック(rules.inputRequired) -->
                <v-autocomplete
                  dense
                  outlined
                  class="text-box"
                  v-model="item.itemStandardSid"
                  :items="itemList"
                  @change="setStockQty(item, index)"
                  :rules="[rules.inputRequired]"
                >
                  <!-- セレクトボックス -->
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </span>
              <!-- 編集不可の場合 -->
              <span v-else class="listNo-style">
                <!-- 品番を表示 -->
                <div>{{ item.itemCd }}<br />{{ item.incidental }}</div>
              </span>
            </template>

            <!-- ロット -->
            <template v-slot:[`item.lotNo`]="{ item, index }">
              <!-- 編集可能な場合 -->
              <span v-if="item.displayFlg && item.addItemCdFlg != '01'">
                <!-- テキストフィールド -->
                <!-- アウトラインデザイン(outlined) -->
                <!-- フィールドの高さを減らす(dense) -->
                <!-- 入力バインディング(item.lotNo) -->
                <!-- 削除ボタンを表示(clearable) -->
                <!-- 数値チェック(rules.isNumber) -->
                <!-- 整数チェック(rules.isInteger) -->
                <!-- 必須チェック(rules.inputRequired) -->
                <!-- 最大桁数チェック(limitNumLength) -->
                <!-- 参照IDを割り当て('lotNo' + index) -->
                <v-text-field
                  outlined
                  dense
                  v-model="item.lotNo"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  maxlength="20"
                  :ref="'lotNo' + index"
                  :disabled="item.approvalFlg == 1 || item.addItemCdFlg == '01'"
                />
              </span>
              <!-- 編集不可の場合 -->
              <span v-else class="listNo-style">
                <!-- 品番を表示 -->
                <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" style="max-width: 343px" v-bind="attrs" v-on="on">
                        {{ item.lotNo }}<br />
                        {{ item.dueDate }}
                      </div>
                    </template>
                    <span
                      >{{ item.lotNo }}<br />
                      {{ item.dueDate }}
                    </span>
                  </v-tooltip>
                </div>
                <div v-else>
                  {{ item.lotNo }}<br />
                  {{ item.dueDate }}
                </div>
              </span>
            </template>

            <!-- 棚卸数 -->
            <template v-slot:[`item.countQty`]="{ item, value, index }">
              <!-- テキストフィールド -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(item.countQty) -->
              <!-- 削除ボタンを表示(clearable) -->
              <!-- 数値チェック(rules.isNumber) -->
              <!-- 整数チェック(rules.isInteger) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <!-- 最大桁数チェック(limitNumLength) -->
              <!-- 変更時イベント(commaCountQty) -->
              <!-- クリック時イベント(clickPop) -->
              <!-- 参照IDを割り当て('countQty' + index) -->
              <v-text-field
                outlined
                dense
                v-model="item.countQty"
                :id="'countQty' + index"
                class="input-number"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[rules.isNumber, rules.isInteger, rules.limitNumLength]"
                @change="cancelValidation(item), commaCountQty(item, index)"
                @click="clickPop(item.countQty)"
                :ref="'countQty' + index"
                :disabled="item.approvalFlg == 1"
                autocomplete="off"
              />
            </template>

            <!-- 差異 -->
            <template v-slot:[`item.difference`]="{ item }">
              <div
                v-if="item.colorFlg == true"
                @change="changeDataStock(item, index)"
                class="align-right"
                style="color: red"
              >
                {{ item.difference }}
              </div>
              <div v-else-if="item.addItemCdFlg == '01'" class="align-right"></div>
              <div
                v-else-if="item.colorFlg == false"
                @change="changeDataStock(item, index)"
                class="align-right"
              >
                {{ item.difference }}
              </div>
            </template>

            <!-- 担当者 -->
            <template v-slot:[`item.person`]="{ item }">
              <!-- オートコンプリート -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- 入力バインディング(item.person) -->
              <!-- 入力候補(userList) -->
              <!-- 最大桁数の設定(maxlength) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <v-autocomplete
                dense
                outlined
                class="text-box"
                v-model="item.person"
                :items="userList"
                maxlength="10"
                :disabled="item.approvalFlg == 1"
                :error-messages="item.requiredCheckMsg"
                autocomplete="off"
              >
                <!-- セレクトボックス -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </template>

            <!-- 削除ボタン -->
            <template v-slot:[`item.delete`]="{ item }">
              <!-- 編集可能な場合 -->
              <span v-if="!item.approvalFlg == 1">
                <!-- 押下時イベント(deleteItem) -->
                <v-btn small @click="deleteItem(item)" text>
                  <!-- ゴミ箱アイコン -->
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </span>
              <!-- 編集不可の場合 -->
              <span v-else> </span>
            </template>
          </v-data-table>
          <!-- ページネーション -->
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(inputList.length / itemsPerPage)"
              :total-visible="7"
            ></v-pagination>
          </div>
        </v-container>
      </v-form>

      <!-- ナビゲーションドロワー -->
      <!-- 開閉状態バインディング(openMenu) -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <!-- サイドメニュー -->
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 登録ボタン押下時確認ダイアログ -->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
        :cancelAction="ConfirmDialog.ngAction"
      />

      <!-- APIメッセージ確認ダイアログ -->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :sixPageFlag="infoDialog.sixPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />

      <!-- 承認済み確認メッセージダイアログモーダル-->
      <v-dialog v-model="approvalDialog.completeDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ approvalDialog.completeMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okcopy"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 戻るボタン押下時確認ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 行追加モーダル -->
      <v-dialog v-model="isOpenCountAchieveAddDialog" :max-width="1700" persistent>
        <CountAchieveAddDialog
          :isOpenDetailDialog.sync="isOpenCountAchieveAddDialog"
          :viewDataList="inputList"
          :locationGroupList="locationGroupList"
          :inventoryDate="inventoryDate"
          :warehouseSid="warehouseSid"
          v-on:child-event="parentMethod"
          :suppliers="suppliersSelected"
          :stockCheckNoPara="stockCheckNo"
          :stockMethodKbnPara="stockMethodKbn"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue"; // ナビゲーションバー
import Loading from "@/components/loading"; // ローディング画面
import { getParameter } from "../../assets/scripts/js/GetParameter"; // マスタ取得
import ConfirmDialog from "../../components/ConfirmDialog"; // 確認ダイアログ
import SimpleDialog from "@/components/SimpleDialog"; // シンプルダイアログ
import sideMenu from "@/components/Menu"; // サイドメニュー
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper"; // 日時ヘルパー
import { i18n } from "../../lang/lang.js"; // 多言語対応
import { commonUtil } from "../../assets/scripts/js/CommonUtil"; // 共通ユーティリティ
import { appConfig } from "../../assets/scripts/js/AppConfig"; // アプリ設定
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil"; // メッセージユーティリティ
import CountAchieveAddDialog from "./CountAchieveAddDialog.vue"; // 行追加モーダルダイアログ

export default {
  // 表示名
  name: appConfig.SCREEN_ID.P_CNT_004,

  // コンポーネント
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
    CountAchieveAddDialog,
  },

  // プロパティ
  props: {
    isMenu: { type: Boolean, default: false },
  },

  // データ
  data: () => ({
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 取引先名
    clientName: "",
    // 取引先SID
    clientSid: "",
    suppliersSelected: "",
    // 取引先リスト
    clientList: [],
    // 棚卸方式名
    inventoryMethodSelectedName: "",
    stockMethodKbn: "",
    // 棚卸日
    inventoryDate: "",
    // 倉庫SID
    warehouseSid: "",
    // 倉庫名
    warehouseName: "",
    // 棚卸計画No
    stockCheckNo: "",
    // 承認メッセージ表示をコントロールフラグ
    controlFlg: false,
    // メニュー
    openMenu: null,
    // 編集された項目のインデックス
    editedIndex: -1,
    // 完了フラグ
    completeFlg: false,
    // APIメッセージ確認ダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      sixPageFlag: false,
      // ダイアログの外クリック制御
      outsideClickNotCloseFlg: false,
    },
    // 確認メッセージダイアログ
    approvalDialog: {
      completeDialogMsg: false,
      completeMessage: "",
    },
    // 登録ボタン押下時確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
      ngAction: () => {},
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 担当者リスト
    userList: [],
    // 品番リスト
    itemList: [],
    // ロケリスト
    locationList: [],
    // ロケグループリスト
    locationGroupList: [],
    // 追加ボタン非活性フラグ
    disableBtnFlg: false,
    // 承認済みフラグ
    approvalFlg: false,
    // 追加条件
    optionsSelect: "",
    optionsList: [],
    option: "",
    // ソートアイテム
    sortItem: "",
    // エラーメッセージ
    requiredCheckMsg: "",
    previousSortedList: [],
    // 行追加フラグ
    addItemCdFlg: "00",
    // 前画面から受け取る検索条件
    coutPlanListParameta: "",
    // 追加ダイアログ
    isOpenCountAchieveAddDialog: false,
    // 検索再実行フラグ
    resetGetApi: false,
    // ソートオプション
    sortOptions: {},
    // ヘッダー項目
    headerItems: [
      // ロケグループ
      {
        text: i18n.tc("label.lbl_locaGroup"),
        value: "locationGroup",
        width: "14%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "15%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 品番
      {
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "15%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 在庫数
      {
        text: i18n.tc("label.lbl_stockQty"),
        value: "stockQty",
        width: "7%",
        align: "right",
        sortable: false,
      },
      // 棚卸数
      {
        text: i18n.tc("label.lbl_countQty"),
        value: "countQty",
        width: "8%",
        align: "right",
        sortable: false,
      },
      // 差異
      {
        text: i18n.tc("label.lbl_differenceHeader"),
        value: "difference",
        width: "7%",
        align: "right",
        sortable: true,
        sort: (a, b) => {
          a = parseInt(a.replace(",", ""));
          b = parseInt(b.replace(",", ""));
          return a - b;
        },
      },
      // 担当者
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "person",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        width: "4%",
        align: "center",
        sortable: false,
      },
    ],
    // 一覧表示リスト
    inputList: [],
    // 修正削除用リスト
    editDeleteList: [],

    // バリデーション
    rules: {
      // 必須チェック
      inputRequired: (value) =>
        (!!value && value.toString().replace(/\s+/g, "") !== "") || i18n.tc("check.chk_input"),
      // 日付形式チェック
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // 数値チェック
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      // 最大桁数チェック
      limitNumLength: (value) =>
        String(value).replaceAll(",", "").length < 10 || i18n.tc("check.chk_limitNumLength"),
      // 整数チェック
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
    },
  }),

  // メソッド
  methods: {
    // 初期表示処理
    init() {
      try {
        this.loadingCounter = 1;
        this.inputList = [];
        this.editDeleteList = [];
        // 前画面からデータを受け取ります。
        // 一覧画面の検索条件
        if (this.$route.params.listSearch != null) {
          this.coutPlanListParameta = this.$route.params.listSearch;
        }
        // 取引先SID
        this.clientSid = this.$route.params.clientSid;
        // 棚卸方式名
        this.inventoryMethodSelectedName = this.$route.params.stockMethod;
        // 棚卸方式
        this.stockMethodKbn = this.$route.params.stockMethodKbn;
        // 棚卸方式が荷動の場合
        if (this.$route.params.stockMethodKbn == "03") {
          // 新規明細追加ボタンを非活性化します。
          this.disableBtnFlg = true;
        }
        // 棚卸日
        this.inventoryDate = dateTimeHelper
          .convertUTC2JST(this.$route.params.countImplementationDate)
          .substr(0, 10);
        // 倉庫SID
        this.warehouseSid = this.$route.params.warehouseSid;
        // 倉庫名
        this.warehouseName = this.$route.params.warehouseName;
        // 棚卸計画No
        this.stockCheckNo = this.$route.params.stockNo;
        // ロケーショングループ一覧
        let locationGr = this.$route.params.locationGp.replace(i18n.tc("label.lbl_locaGroup"), "");
        let locationGrArr = locationGr.split(",");
        for (let i = 0; i < locationGrArr.length; i++) {
          this.locationGroupList.push({
            value: locationGrArr[i],
            name: i18n.tc("label.lbl_locaGroup") + locationGrArr[i],
            text: i18n.tc("label.lbl_locaGroup") + locationGrArr[i],
          });
        }
        // APIを呼び出します。
        // 関係先マスタGET・追加条件：コード管理マスタ取得API
        this.getRelatedParties();
        // 棚卸実績GET
        let result = this.getStockCheckAchievement();

        // プルダウンにセットする値を取得します。
        Promise.all([result])
          .then(() => {
            // console.debug("init() Result[0]", result[0]);
            // 画面の初期値を設定します。
            this.getUsers();
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // 処理なし
          });
      } finally {
        this.loadingCounter = 0;
      }
    },
    /**
     * 完了ボタン：完了処理
     */
    completePush() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_complete"),
      ]);
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.complete;
    },
    complete() {
      // ローディング画面を表示します。
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_004;
      body.reqIdv.stockCheckOrder = {
        stockCheckNo: this.$route.params.stockNo, //棚卸指示No
        stockCheckDiv: this.$route.params.stockMethodKbn, //棚卸方法区分
        date: dateTimeHelper.convertUTC(this.inventoryDate), //棚卸実施日
        warehouseSid: this.$route.params.warehouseSid, //倉庫SID
        stockConditon: "01", //追加条件
        clientSid: this.clientSid, //取引先SID
      };

      // console.debug("complete() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.CNT_COMPLETE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("complete() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_complete"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.sixPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.infoDialog.firstPageFlag = false;
              resolve(response);
              // エラー時
            } else {
              if (jsonData.resCom.resComMessage == "stockResultSid必須です。") {
                this.infoDialog.message = messsageUtil.getMessage("P-CNT-004_001_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面を非表示にします。
            this.loadingCounter = 0;
          });
      });
    },
    // 再検索：追加条件を選択しなおす
    resetSearchApi(oldValue) {
      this.option = oldValue;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_004_W");
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.getStockCheckAchievement;
      this.ConfirmDialog.ngAction = this.searchNg;
    },

    // 関係先マスタGET
    getRelatedParties() {
      // ローディング画面を表示します。
      // this.loadingCounter = 1;

      // 取引先取得を実行します。
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // 追加条件
      const optionList = getParameter.getCodeMst(appConfig.CODETYPE.OPTION_TYPE);

      Promise.all([clientList, optionList])
        // 取引先取得が完了した場合
        .then((result) => {
          // 取引先リストを設定します。
          this.clientList = result[0];
          // 追加条件リストを取得
          this.optionsList = result[1];
          // 追加条件の初期表示設定
          this.optionsSelect = this.optionsList[0].value;
          // 取引先リストに対してループします。
          for (let i = 0; i < this.clientList.length; i++) {
            // 取引先SIDが一致する場合
            if (this.clientSid == this.clientList[i].value) {
              // 取引先名を設定します。
              this.clientName = this.clientList[i].text;
            }
          }
        })
        .catch((ex) => {
          // 例外処理
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面を非表示にします。
          // this.loadingCounter = 0;
        });
    },

    // 棚卸実績GET
    getStockCheckAchievement() {
      this.inputList = [];

      if (this.optionsSelect == null || this.optionsSelect == "") {
        // 処理なし
      } else {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // GETリクエストを生成します。
        const config = this.$httpClient.createGetApiRequestConfig();

        // 営業所SIDを設定します。
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 棚卸指示Noを設定します。
        config.params.stockCheckNo = this.stockCheckNo;
        // 棚卸方法区分を設定します。
        config.params.stockCheckDiv = this.$route.params.stockMethodKbn;
        // 棚卸実施日を設定します。
        config.params.date = dateTimeHelper.convertUTC(this.inventoryDate);
        // 倉庫SID
        config.params.warehouseSid = this.warehouseSid;
        // 取引先SID
        config.params.clientSid = this.clientSid;
        // 追加条件
        config.params.stockConditon = this.optionsSelect;
        // console.debug("getStockCheckAchievement() Config", config);

        return new Promise((resolve, reject) => {
          this.$httpClient
            .secureGet(appConfig.API_URL.CNT_GETACHIEVE, config)
            .then((response) => {
              // console.debug("getStockCheckAchievement() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                jsonData.resIdv.stockCheckAchievement.forEach((row) => {
                  let lotObj = commonUtil.convertLotNo(row.lotNo);
                  list.push({
                    stockCheckNo: this.stockCheckNo, // 棚卸指示No
                    stockResultSid: row.stockResultSid, // 棚卸結果SID
                    stockCheckSid: row.stockCheckSid, // 棚卸指示SID
                    integrateFlg: row.integrateFlg, // 統合判定FLG
                    yyyymmddHhmmss: dateTimeHelper.convertUTC2JST(row.yyyymmddHhmmss), // 日時
                    warehouseSid: this.warehouseSid, // 倉庫SID
                    locationSid: row.locationSid, // ロケーションSID
                    locationGroup: row.locationGroup, // ロケーショングループ
                    location: row.locationNo, // ロケーションNo
                    stockCheckDiv: this.$route.params.stockMethodKbn, // 棚卸方式
                    stockCheckName: row.stockCheckName, // 棚卸方法名
                    freeCostDiv: row.freeCostDiv, // 有償無償区分
                    // 実績登録済みの場合は実績担当者を表示、計画のみの場合は計画時の担当者
                    userSid: row.achievedUserSid ? row.achievedUserSid : row.plannedUserSid, // 担当者SID
                    userName: row.achievedUserName ? row.achievedUserName : row.plannedUserName, // 担当者名
                    person: row.achievedUserSid ? row.achievedUserSid : row.plannedUserSid, // 画面表示用
                    stockQty:
                      // ブランクならばブランク、数値ならば小数点以下を削除
                      row.dataStock == "" ? "" : commonUtil.formatToCurrency(Number(row.dataStock)), // 在庫数
                    // 棚卸結果SIDがなく、棚卸数が0の場合は空で表示＝実績なし
                    countQty:
                      // 指示が完了していないかつ実績数0ならば空欄、それ以外は実績数を表示
                      row.stockCheckOrderCompleteFlg == "0" &&
                      row.isResult == "0" &&
                      Number(row.takenStock) == 0
                        ? ""
                        : commonUtil.formatToCurrency(Number(row.takenStock)), // 棚卸数
                    difference: commonUtil.formatToCurrency(row.takenStock - row.dataStock), // 差異
                    itemCdShow: row.itemCd, // 品番
                    itemCd: row.itemCd.substring(0, 50).trim(), // 品番
                    incidental: row.itemCd.slice(-50).trim(), //付帯品番
                    itemStandardSid: row.itemStandardSid, // 商品標準SID
                    lotNoShow: row.lotNo, // ロットNo
                    lotNo: lotObj.lot, // ロット、S/N
                    dueDate: lotObj.dueDate, // 期限日、付属キー１、付属キー２
                    displayFlg: false, // 編集Flg
                    colorFlg: false, // カラーフラグ
                    deleteFlg: "0", // 削除フラグ
                    updateDatetime: row.stockCheckResultUpdateDatetime, // 更新日時
                    approvalFlg: row.approvalFlg, // 承認フラグ
                    addItemCdFlg: "00", // 行追加フラグ
                    stockCheckOrderUpdateDatetime: row.stockCheckOrderUpdateDatetime, // 指示更新日時
                    autoNo: row.autoNo, // 連番
                    stockCheckOrderCompleteFlg: row.stockCheckOrderCompleteFlg, // 完了フラグ
                  });
                });
                // 差異列：文字を色付け
                for (var i = 0; i < list.length; i++) {
                  if (list[i].difference.replaceAll(",", "") < 0) {
                    list[i].colorFlg = true;
                  } else {
                    list[i].colorFlg = false;
                  }
                }
                // 表示用
                this.inputList = list;
                this.previousSortedList = JSON.parse(JSON.stringify(this.inputList));
                this.checkValidFlg();

                // 修正削除用
                this.editDeleteList = list.slice();

                // すべて承認済みか判定
                this.approvalFlg = list.every((val) => {
                  return val.approvalFlg == "1";
                });

                resolve(response);

                if (!this.controlFlg && this.approvalFlg) {
                  this.approvalDialog.completeMessage = messsageUtil.getMessage("P-CNT-006_004_C");
                  this.approvalDialog.completeDialogMsg = true;
                }
                this.controlFlg = true;
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面を非表示にします。
              this.loadingCounter = 0;
              this.resetGetApi = false;
              this.option = this.optionsSelect;
            });
        });
      }
    },
    // 完了フラグがひとつでもなければ行追加ボタンを非活性にする
    checkValidFlg() {
      var cnt = 0;
      for (var t = 0; t < this.inputList.length; t++) {
        if (this.inputList[t].stockCheckOrderCompleteFlg == "0") {
          cnt = cnt + 1;
        }
      }
      if (cnt == 0) {
        this.completeFlg = true;
      }
    },
    // 完了フラグダイアログを閉じる
    okcopy() {
      this.approvalDialog.completeDialogMsg = false;
    },
    // ユーザマスタGET
    getUsers() {
      // ローディング画面を表示します。
      // this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // ★必須パラメータ
      config.params.searchCategoryDiv = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getUsers() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                  name: row.userName,
                  userId: row.userId, // ユーザーID
                });
              });
              this.userList = list;

              // 取得したユーザーリストの中から、明細のユーザーSidと一致するものを探す
              for (var i = 0; i < this.inputList.length; i++) {
                for (var ii = 0; ii < this.userList.length; ii++) {
                  if (this.inputList[i].userSid == this.userList[ii].value) {
                    // inputListの担当者（person）に一致したユーザーSidを入れる
                    this.inputList[i].person = this.userList[ii].value;
                  }
                }
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面を非表示にします。
            // this.loadingCounter = 0;
          });
      });
    },

    // 棚卸実績POST処理
    postApi() {
      // ローディング画面を表示します。
      this.loadingCounter = 1;

      // プルダウンの値を変更して詰める必要があるため新しいリストを作る
      var list = [];

      // 削除する明細があるかチェック
      var deleteIncludeFlg = false;
      for (var dd = 0; dd < this.editDeleteList.length; dd++) {
        if (this.editDeleteList[dd].deleteFlg == "1") {
          deleteIncludeFlg = true;
          break;
        }
      }

      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      let nowDate = dateTimeHelper.convertUTC(today);

      // 削除明細が１つでもあった場合
      if (deleteIncludeFlg) {
        for (let i = 0; i < this.editDeleteList.length; i++) {
          var inputMap = {};
          inputMap.locationGroup = this.editDeleteList[i].locationGroup; // ロケーショングループ
          if (this.editDeleteList[i].lotNoShow != null) {
            inputMap.lotNo = this.editDeleteList[i].lotNoShow; // ロット
          }
          inputMap.userSid = this.editDeleteList[i].person; // 担当者SID
          inputMap.deleteFlg = this.editDeleteList[i].deleteFlg; // 削除フラグ;
          inputMap.stock = this.editDeleteList[i].countQty.replaceAll(",", ""); // 数量
          // 統合判定Flg
          if (this.editDeleteList[i].integrateFlg != null) {
            inputMap.integrateFlg = this.editDeleteList[i].integrateFlg;
          }
          // 棚卸結果SID
          if (this.editDeleteList[i].stockResultSid != null) {
            inputMap.stockResultSid = this.editDeleteList[i].stockResultSid;
          }
          // 更新日時
          if (this.editDeleteList[i].updateDatetime != null) {
            inputMap.updateDatetime = this.editDeleteList[i].updateDatetime;
          }
          if (this.editDeleteList[i].itemCdShow != null) {
            inputMap.itemCd = this.editDeleteList[i].itemCdShow; // 品番
          }
          if (this.editDeleteList[i].itemStandardSid != null) {
            inputMap.itemStandardSid = this.editDeleteList[i].itemStandardSid; // 商品標準SID
          }
          inputMap.locationSid = this.editDeleteList[i].locationSid; // ロケーションSID
          if (this.editDeleteList[i].stockCheckSid != null) {
            inputMap.stockCheckSid = this.editDeleteList[i].stockCheckSid; // 棚卸指示SID*条件
          }
          inputMap.completeFlg = "0"; // 完了とする
          list.push(inputMap);
        }

        // 削除明細がない場合
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          inputMap = {};
          inputMap.locationGroup = this.inputList[i].locationGroup; // ロケーショングループ
          if (this.inputList[i].lotNoShow != null) {
            inputMap.lotNo = this.inputList[i].lotNoShow; // ロット
          }
          inputMap.userSid = this.inputList[i].person; // 担当者SID
          inputMap.deleteFlg = "0"; // 削除フラグ;
          if (this.inputList[i].countQty) {
            inputMap.stock = this.inputList[i].countQty.replaceAll(",", ""); // .replaceAll(",", ""); // 数量
          } else {
            inputMap.stock = null; // .replaceAll(",", ""); // 数量
          }
          if (this.inputList[i].stockResultSid != null) {
            inputMap.stockResultSid = this.inputList[i].stockResultSid; // 結果指示SID
          }
          // 統合判定Flg
          if (this.inputList[i].integrateFlg != null) {
            inputMap.integrateFlg = this.inputList[i].integrateFlg;
          }
          if (this.inputList[i].updateDatetime != null) {
            inputMap.updateDatetime = this.inputList[i].updateDatetime; // 更新日時
          }
          if (this.inputList[i].itemCdShow != null) {
            inputMap.itemCd = this.inputList[i].itemCdShow; // 品番
          }
          if (this.inputList[i].itemStandardSid != null) {
            inputMap.itemStandardSid = this.inputList[i].itemStandardSid; // 商品標準SID
          }
          inputMap.locationSid = this.inputList[i].locationSid; // ロケーションSID
          if (this.inputList[i].stockCheckSid != null) {
            inputMap.stockCheckSid = this.inputList[i].stockCheckSid; // 棚卸指示SID*条件
          }
          inputMap.completeFlg = "0"; // 完了とする

          list.push(inputMap);
        }
      }

      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_004;
      body.reqIdv.stockCheckResult = list;

      body.reqIdv.yyyymmddHhmmss = nowDate; // 日時
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid"); // 営業所SID
      body.reqIdv.clientSid = this.clientSid; // 取引先SID
      body.reqIdv.stockCheckNo = this.$route.params.stockNo; // 棚卸指示No
      body.reqIdv.warehouseSid = this.$route.params.warehouseSid; // 倉庫SID
      body.reqIdv.stockCheckDiv = this.$route.params.stockMethodKbn; // 棚卸方式

      // console.debug("postApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.CNT_ADDACHIEVE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.sixPageFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.infoDialog.firstPageFlag = false;
              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面を非表示にします。
            this.loadingCounter = 0;
          });
      });
    },
    // 登録ボタン押下**登録しますかのアラート**
    actualAdd() {
      let cnt = 0;

      // 必須項目の入力がされているかかチェック
      if (this.$refs.editList.validate()) {
        for (var m = 0; m < this.inputList.length; m++) {
          if (this.inputList[m].countQty) {
            // 移動数カンマ除去
            var countQty = Number(
              commonUtil.zen2han(this.inputList[m].countQty).replaceAll(",", "")
            );
            // 数値チェック
            if (isNaN(countQty)) {
              cnt = m + 1;
            }
            // 整数チェック
            if (!Number.isInteger(countQty)) {
              cnt = m + 1;
            }

            // 範囲チェック（0未満）
            if (countQty < 0) {
              cnt = m + 1;
            }
          }
        }
      } else {
        // 必須項目入力がない場合、入力エラー
        return;
      }

      // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
      if (cnt == 0) {
        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
        this.ConfirmDialog.okAction = this.postApi;
      } else {
        for (var j = 0; j < this.inputList.length; j++) {
          // 関係ないレコードの入力チェックエラーを解除
          if (j !== cnt - 1) {
            eval("this.$refs.countQty" + j + ".resetValidation()");
          }
        }
      }
    },

    // 戻るボタン押下
    backPage() {
      if (this.approvalFlg) {
        this.updateOk();
      } else {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
      }
    },

    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 元画面が一覧の場合
      this.$router.push({
        name: appConfig.MENU_ID.P_CNT_PLAN_LIST,
        params: {
          returnParam: this.coutPlanListParameta, // 検索条件
        },
      });
    },

    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    // 再検索タンダイアログ：NG押下
    searchNg() {
      this.optionsSelect = this.option;
      this.ConfirmDialog.isOpen = false;
    },

    // ロケグループのプルダウン値をセット
    filterGroup() {
      var locationFilter = this.locationList.filter((e, index, arry) => {
        return arry.findIndex((el) => e.group == el.group) === index;
      });
      return locationFilter;
    },

    // 在庫数セット処理
    setStockQty(val, index) {
      // 商品リストの中から選択された品番と一致するものを割り出し
      for (var i = 0; i < this.itemList.length; i++) {
        if (this.inputList[index].itemCdShow == this.itemList[i].value) {
          // 在庫数に反映させる
          // this.inputList[index].stockQty = this.itemList[i].quantity;
        }
      }
    },

    // 削除ボタン
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      this.inputList.splice(this.editedIndex, 1);

      var editedIndex = this.editDeleteList.indexOf(item);
      this.editDeleteList[editedIndex].deleteFlg = "1";
    },

    // 新規明細追加
    newDetailAdd() {
      this.isOpenCountAchieveAddDialog = true;
      this.suppliersSelected = this.clientSid;
    },
    // 棚卸カンマ区切り処理
    commaCountQty(val) {
      var comma = commonUtil.zen2han(val.countQty).replaceAll(",", "");
      comma = comma.replace(/[^0-9]/gi, "");
      if (comma.length > 9) {
        comma = comma.substring(0, 9);
      }
      if (!isNaN(Number(comma))) {
        val.countQty = commonUtil.formatToCurrency(Number(comma));
        if (comma != null && comma != "") {
          // 棚卸数が入力されている場合
          val.difference = commonUtil.formatToCurrency(
            Math.ceil(comma - val.stockQty.replaceAll(",", ""))
          );
          if (val.difference.replaceAll(",", "") < 0) {
            val.colorFlg = true;
          } else {
            val.colorFlg = false;
          }
        } else {
          val.difference = commonUtil.formatToCurrency(
            Math.ceil(val.countQty - val.stockQty.replaceAll(",", ""))
          );
          if (val.difference.replaceAll(",", "") < 0) {
            val.colorFlg = true;
          } else {
            val.colorFlg = false;
          }
        }
      }
    },
    /**
     * 棚卸実績登録画面：入力カンマ処理 差異数
     */
    changeDataStock(val) {
      var difference = commonUtil.zen2han(val.difference).replaceAll(",", "");
      difference = difference.replace(/[^0-9]/gi, "");
      if (difference.length > 9) {
        difference = difference.substring(0, 9);
      }
      if (!isNaN(Number(difference))) {
        val.difference = commonUtil.formatToCurrency(Number(difference));
      }
    },

    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * 不要なバリデーションチェックを抑止する
     */
    cancelValidation(val) {
      if (this.inputList.length === 1) {
        return;
      }

      let elements = document.querySelectorAll("[id^='countQty']");
      console.log(elements);

      let sortedList;
      let updateObj = this.previousSortedList.filter((value) => {
        return (
          value.locationSid == val.locationSid &&
          value.itemStandardSid == val.itemStandardSid &&
          value.lotNoShow == val.lotNoShow &&
          value.stockQty == val.stockQty
        );
      });
      const previousIndex = this.previousSortedList.indexOf(updateObj[0]);
      this.previousSortedList[previousIndex].difference =
        new Number(commonUtil.zen2han(val.countQty).replaceAll(",", "")) -
        new Number(commonUtil.zen2han(val.stockQty).replaceAll(",", ""));
      switch (this.ascdesc) {
        case "asc":
          sortedList = this.previousSortedList.sort((obj1, obj2) => {
            if (
              new Number(commonUtil.zen2han(obj1.difference).replaceAll(",", "")) <
              new Number(commonUtil.zen2han(obj2.difference).replaceAll(",", ""))
            )
              return -1;
            if (
              new Number(commonUtil.zen2han(obj1.difference).replaceAll(",", "")) >
              new Number(commonUtil.zen2han(obj2.difference).replaceAll(",", ""))
            )
              return 1;
            if (obj1.locationGroup < obj2.locationGroup) return -1;
            if (obj1.locationGroup > obj2.locationGroup) return 1;
            if (obj1.location < obj2.location) return -1;
            if (obj1.location > obj2.location) return 1;
            if (obj1.itemCdShow < obj2.itemCdShow) return -1;
            if (obj1.itemCdShow > obj2.itemCdShow) return 1;
            if (obj1.lotNoShow < obj2.lotNoShow) return -1;
            if (obj1.lotNoShow > obj2.lotNoShow) return 1;
            if (obj1.stockQty < obj2.stockQty) return -1;
            if (obj1.stockQty > obj2.stockQty) return 1;
          });
          break;
        case "desc":
          sortedList = this.previousSortedList.sort((obj1, obj2) => {
            if (
              new Number(commonUtil.zen2han(obj1.difference).replaceAll(",", "")) >
              new Number(commonUtil.zen2han(obj2.difference).replaceAll(",", ""))
            )
              return -1;
            if (
              new Number(commonUtil.zen2han(obj1.difference).replaceAll(",", "")) <
              new Number(commonUtil.zen2han(obj2.difference).replaceAll(",", ""))
            )
              return 1;
            if (obj1.locationGroup < obj2.locationGroup) return -1;
            if (obj1.locationGroup > obj2.locationGroup) return 1;
            if (obj1.location < obj2.location) return -1;
            if (obj1.location > obj2.location) return 1;
            if (obj1.itemCdShow < obj2.itemCdShow) return -1;
            if (obj1.itemCdShow > obj2.itemCdShow) return 1;
            if (obj1.lotNoShow < obj2.lotNoShow) return -1;
            if (obj1.lotNoShow > obj2.lotNoShow) return 1;
            if (obj1.stockQty < obj2.stockQty) return -1;
            if (obj1.stockQty > obj2.stockQty) return 1;
          });
          break;
        default:
          sortedList = this.previousSortedList;
          break;
      }
      let index = sortedList.indexOf(updateObj[0]);

      this.$nextTick(() => {
        // 並び順に変更がある場合は、テーブルのDOM更新後にバリデーション結果をリセットする
        if (previousIndex != index) {
          this.$refs.editList.resetValidation();
        }
      });
      this.previousSortedList = sortedList;
    },
    parentMethod: function (isConfig, target) {
      if (isConfig) {
        this.updateDialogMessage = false;
        var found = this.inputList.find(
          (o) =>
            o.locationSid == target.locationSid &&
            o.location === target.location &&
            o.locationGroup == target.locationGroup &&
            o.itemCd === target.itemCd.substring(0, 50).trim() &&
            o.itemStandardSid === target.itemStandardSid &&
            o.lotNo == target.lotNo &&
            o.serial == target.serial &&
            o.dueDate == target.dueDate &&
            o.lotSubkey1 == target.lotSubkey1 &&
            o.lotSubkey2 == target.lotSubkey2
        );
        if (found) {
          found.countQty = commonUtil.formatToCurrency(
            Number(found.countQty.split(",").join("")) + Number(target.countQty.split(",").join(""))
          );
          found.difference = commonUtil.formatToCurrency(
            Number(found.countQty.split(",").join("")) - Number(found.stockQty.split(",").join(""))
          );
          if (found.difference.replaceAll(",", "") < 0) {
            found.colorFlg = true;
          } else {
            found.colorFlg = false;
          }
          if (target.person.trim() != "" && found.person.trim() != target.person) {
            found.userSid = target.userSid;
            found.person = target.person;
          }
        } else {
          this.inputList.push(target);
        }
        this.resetGetApi = true;
      }
    },
  },

  // 監視
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        // 初期表示処理を実行
        this.init();
      }
    },

    dialog(val) {
      val || this.close();
    },

    optionsSelect(newValue, oldValue) {
      // console.debug("watch", newValue, oldValue);
      // 追加条件
      if (!this.resetGetApi) {
        this.getStockCheckAchievement();
      } else if (newValue != this.option) {
        this.resetSearchApi(oldValue);
      }
    },
    sortOptions: {
      handler() {
        let replaceTarget = this.inputList.filter((element) => {
          return element.difference.includes(",") == true;
        });
        this.$nextTick(() => {
          replaceTarget.forEach((row) => {
            row.colorFlg = parseInt(row.difference.replaceAll(",", "")) < 0;
          });
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.editList.resetValidation();
    });
  },
  // Vueインスタンスが生成された後
  created() {
    // 初期表示処理を実行
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}
</style>
