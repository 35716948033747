<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_countAchieveApproval" />
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-autocomplete first-search-item">
              <!-- 取引先-->
              <v-text-field
                dense
                id="get-supplier"
                v-model="suppliersSelectedName"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="search-autocomplete">
              <!-- 棚卸方式 -->
              <v-text-field
                dense
                v-model="inventoryMethodSelectedName"
                :label="$t('label.lbl_invatoryMethod')"
                class="txt-single"
                persistent-hint
                readonly
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- 棚卸日-->
              <v-text-field
                dense
                v-model="countImplementationDateJ"
                :label="$t('label.lbl_invatoryDate')"
                class="txt-single"
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!--倉庫-->
              <v-text-field
                dense
                v-model="warehouseSelectedName"
                :label="$t('label.lbl_warehouse')"
                class="txt-single"
                readonly
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--棚卸計画No-->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                dense
                v-model="stockNo"
                maxlength="30"
                :label="$t('label.lbl_countInstNo')"
                class="txt-single"
                readonly
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 追加条件 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="optionsSelect"
                :items="optionsList"
                :label="$t('label.lbl_options')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--戻るボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" class="api-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div class="btn-area">
                <!--承認ボタン-->
                <v-btn
                  color="primary"
                  :disabled="disableEdit"
                  class="api-btn"
                  @click="planApproval"
                  >{{ $t("btn.btn_approval") }}</v-btn
                >
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :ripple="false"
          disable-filtering
          :hide-default-footer="true"
          height="600px"
          :options.sync="sortOptions"
          :sort-by.sync="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <!-- ロット -->
          <template v-slot:[`header.lotNo`]="{ header }">
            <span>
              {{ header.text }}<br />
              {{
                $t("label.lbl_dueDate") +
                "／" +
                $t("label.lbl_lotSubkey1") +
                "／" +
                $t("label.lbl_lotSubkey2")
              }}
            </span>
          </template>

          <!-- <template v-slot:[`item.locationGroup`]="{ item }"
            >{{ $t("label.lbl_locaGroup") }} {{ item.locationGroup }}
          </template> -->
          <!-- 担当者 -->
          <!-- <template v-slot:[`item.popDestinationNm`]="{ item }">
            <v-autocomplete
              dense
              outlined
              class="cursorProcd input-number"
              v-model="item.popDestinationNm"
              :items="userList"
              maxlength="10"
            />
          </template> -->
          <!-- 備考 -->
          <!-- <template v-slot:[`item.remarks`]="{ item }">
            <v-text-field
              dense
              outlined
              class="txt-single"
              counter="100"
              v-model="item.remarks"
              maxlength="100"
              :disabled="item.approvalFlg == 1"
            />
          </template> -->
          <template v-slot:[`item.delete`]="{ item }">
            <v-btn small @click="deleteItem(item)" text> <v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <!-- ロケグループ -->
          <template v-slot:[`item.locationGroup`]="{ item }">
            <!-- 編集可能な場合 -->
            <span v-if="item.displayFlg">
              <!-- オートコンプリート -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- 入力バインディング(item.locationGroup) -->
              <!-- 入力候補(locationGroupList) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <v-autocomplete
                dense
                outlined
                class="text-box"
                v-model="item.locationGroup"
                :items="locationGroupList"
                :rules="[rules.inputRequired]"
              >
                <!-- セレクトボックス -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </span>
            <!-- 編集不可の場合 -->
            <span v-else-if="!item.displayFlg">
              <!-- ロケグループを表示 -->
              {{ $t("label.lbl_locaGroup") }} {{ item.locationGroup }}
            </span>
          </template>
          <!-- ロケ -->
          <template v-slot:[`item.location`]="{ item }">
            <!-- 編集可能な場合 -->
            <span v-if="item.displayFlg">
              <!-- オートコンプリート -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- 入力バインディング(item.location) -->
              <!-- 入力候補(locationList) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <v-autocomplete
                dense
                outlined
                class="text-box"
                v-model="item.location"
                :items="locationList"
                :rules="[rules.inputRequired]"
              >
                <!-- セレクトボックス -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </span>
            <!-- 編集不可の場合 -->
            <span v-else-if="!item.displayFlg">
              <!-- ロケを表示 -->
              {{ item.location }}
            </span>
          </template>

          <!-- 品番 -->
          <template v-slot:[`item.itemCd`]="{ item, index }">
            <!-- 編集可能な場合 -->
            <span v-if="item.displayFlg">
              <!-- オートコンプリート -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- 入力バインディング(item.itemStandardSid) -->
              <!-- 入力候補(itemList) -->
              <!-- 変更時イベント(setStockQty) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <v-autocomplete
                dense
                outlined
                class="text-box"
                v-model="item.itemStandardSid"
                :items="itemList"
                @change="setStockQty(item, index)"
                :rules="[rules.inputRequired]"
              >
                <!-- セレクトボックス -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </span>
            <!-- 編集不可の場合 -->
            <span v-else-if="!item.displayFlg" class="listNo-style">
              <!-- 品番を表示 -->
              <div>{{ item.itemCd }}<br />{{ item.incidental }}</div>
            </span>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lotNo`]="{ item, index }">
            <!-- 編集可能な場合 -->
            <span v-if="item.displayFlg">
              <!-- テキストフィールド -->
              <!-- アウトラインデザイン(outlined) -->
              <!-- フィールドの高さを減らす(dense) -->
              <!-- 入力バインディング(item.countQty) -->
              <!-- 削除ボタンを表示(clearable) -->
              <!-- 数値チェック(rules.isNumber) -->
              <!-- 整数チェック(rules.isInteger) -->
              <!-- 必須チェック(rules.inputRequired) -->
              <!-- 最大桁数チェック(limitNumLength) -->
              <!-- 参照IDを割り当て('countQty' + index) -->
              <v-text-field
                outlined
                dense
                v-model="item.lotNo"
                class="text-box"
                clear-icon="mdi-close-circle"
                clearable
                maxlength="20"
                :ref="'lotNo' + index"
                :disabled="item.approvalFlg == 1"
              />
            </span>
            <!-- 編集不可の場合 -->
            <span v-else-if="!item.displayFlg" class="listNo-style">
              <!-- 品番を表示 -->
              <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 300px" v-bind="attrs" v-on="on">
                      {{ item.lotNo }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <span
                    >{{ item.lotNo }}<br />
                    {{ item.dueDate }}
                  </span>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.lotNo }}<br />
                {{ item.dueDate }}
              </div>
            </span>
          </template>

          <!-- 棚卸数 -->
          <template v-slot:[`item.countQty`]="{ item, value, index }">
            <!-- テキストフィールド -->
            <!-- アウトラインデザイン(outlined) -->
            <!-- フィールドの高さを減らす(dense) -->
            <!-- 入力バインディング(item.countQty) -->
            <!-- 削除ボタンを表示(clearable) -->
            <!-- 数値チェック(rules.isNumber) -->
            <!-- 整数チェック(rules.isInteger) -->
            <!-- 必須チェック(rules.inputRequired) -->
            <!-- 最大桁数チェック(limitNumLength) -->
            <!-- 変更時イベント(commaCountQty) -->
            <!-- クリック時イベント(clickPop) -->
            <!-- 参照IDを割り当て('countQty' + index) -->
            <v-text-field
              outlined
              dense
              v-model="item.countQty"
              class="input-number"
              @change="commaCountQty(item, index)"
              @click="clickPop(item.countQty)"
              :id="'countQty' + index"
              :ref="'countQty' + index"
              :disabled="true"
            />
          </template>

          <!-- 差異 -->
          <template v-slot:[`item.difference`]="{ item }">
            <div v-if="item.colorFlg == true" class="align-right" style="color: red">
              {{ item.difference }}
            </div>
            <div v-else class="align-right">
              {{ item.difference }}
            </div>
          </template>
          <!-- 担当者 -->
          <template v-slot:[`item.person`]="{ item }">
            <!-- オートコンプリート -->
            <!-- フィールドの高さを減らす(dense) -->
            <!-- アウトラインデザイン(outlined) -->
            <!-- 入力バインディング(item.person) -->
            <!-- 入力候補(userList) -->
            <!-- 最大桁数の設定(maxlength) -->
            <!-- 必須チェック(rules.inputRequired) -->
            <v-autocomplete
              outlined
              dense
              class="text-box"
              v-model="item.person"
              :items="userList"
              maxlength="10"
              :disabled="true"
            >
              <!-- セレクトボックス -->
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
          <!-- 備考 -->
          <template v-slot:[`item.remarks`]="{ item }">
            <v-text-field
              dense
              outlined
              class="input-number"
              counter="100"
              v-model="item.remarks"
              maxlength="100"
              :disabled="item.approvalFlg == 1"
            />
          </template>
        </v-data-table>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(inputList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :cntapprovalFlg="infoDialog.cntapprovalFlg"
      />
      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 完了フラグダイアログ -->
      <v-dialog v-model="completeDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ completeMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okcopy"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "../../components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_CNT_006,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // 完了フラグダイアログ
    completeDialogMsg: "",
    completeMessage: "",
    // 編集制御フラグ
    disableEdit: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 前のページから受け取る
    person: "", //担当者
    warehouse: "", //倉庫
    location: "", //ロケーション
    // 取引先プルダウン
    suppliersSelectedName: "",
    suppliersSelected: "",
    supplierList: [],
    // 棚卸日
    inventoryDateCal: "",
    inventoryDate: "",
    inventoryDateMenu: false,
    // 棚卸方式プルダウン
    inventoryMethodSelected: "",
    inventoryMethodList: [],
    inventoryMethodSelectedName: "",
    // 倉庫プルダウン
    warehouseSelected: "",
    warehouseSelectedName: "",
    warehouseList: [],
    // 商品標準SID
    itemStandardSid: "",
    // ロット
    lotNo: "",
    // 棚卸数
    countQty: "",
    // 在庫数
    stockQty: "",
    // 品番リスト
    itemList: [],
    // 空フラグ
    // colorFlg: true,
    // 追加条件
    optionsSelect: "",
    optionsList: [],
    // ソートアイテム
    sortItem: "",
    sortOptions: {},
    sortBy: "",
    // 棚卸指示No
    inventoryPlanNo: "",
    // 前画面のページ名
    beforePage: "",
    // メニュー
    openMenu: null,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      cntapprovalFlg: false,
    },
    // 登録ボタン押下時確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 棚卸実績登録チェックフラグ
    confirmCheckDialogFlg: true,
    // 承認メッセージ表示をコントロールフラグ
    controlFlg: false,
    // 総件数
    totalCount: 0,
    // 担当者リスト
    userList: [],
    inputList: [],
    headerItems: [
      // ロケグループ
      {
        text: i18n.tc("label.lbl_locationGroup"),
        value: "locationGroup",
        width: "5%",
        align: "left",
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "4%",
        align: "left",
        sortable: false,
      },
      // 品番
      {
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "9%",
        align: "left",
        sortable: false,
      },
      // 在庫数
      {
        text: i18n.tc("label.lbl_stockQty"),
        value: "stockQty",
        width: "5%",
        align: "right",
        sortable: false,
      },
      // 棚卸数
      {
        text: i18n.tc("label.lbl_countQty"),
        value: "countQty",
        width: "5%",
        align: "right",
        sortable: false,
      },
      // 差異
      {
        text: i18n.tc("label.lbl_differenceHeader"),
        value: "difference",
        width: "3%",
        align: "right",
        sortable: true,
        sort: (a, b) => {
          a = parseInt(a.replace(",", ""));
          b = parseInt(b.replace(",", ""));
          return a - b;
        },
      },
      // 担当者
      {
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "person",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarksTextbox"),
        value: "remarks",
        width: "10%",
        align: "center",
        sortable: false,
      },
    ],
    // 前画面より受け取る値
    stockNo: "",
    backpage: "",
    stockCheckDiv: "",
    officeSid: "",
    warehouseSid: "",
    countImplementationDateJ: "",
    From: "",
    manager: "",
    clientSid: "",
    FromCal: "",
    To: "",
    ToCal: "",
    coutPlanListParameta: "", //検索条件
    // 入力チェック
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // 数値チェック
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      // 最大桁数チェック
      limitNumLength: (value) =>
        String(value).replaceAll(",", "").length < 10 || i18n.tc("check.chk_limitNumLength"),
      // 整数チェック
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
    },
  }),
  methods: {
    init() {
      try {
        this.loadingCounter = 1;
        // 前画面の検索条件を一時保存(ヘッダー部分)
        if (this.$route.params.listSearch != null) {
          this.coutPlanListParameta = this.$route.params.listSearch;
        }
        this.stockNo = this.$route.params.stockNo;
        this.inventoryMethodSelected = this.$route.params.stockCheckDiv;
        this.suppliersSelected = this.$route.params.clientSid;
        this.warehouseSelected = this.$route.params.warehouseSid;
        this.date = this.$route.params.date;
        this.clientSid = this.$route.params.clientSid;
        // 前画面の検索条件を一時保存
        this.countImplementationDateJ = this.$route.params.countImplementationDateJ;
        this.manager = this.$route.params.manager;
        this.warehouse = this.$route.params.warehouse;
        this.location = this.$route.params.location;
        this.From = this.$route.params.From;
        this.To = this.$route.params.To;
        this.backpage = this.$route.params.name;
        this.FromCal = this.$route.params.FromCal;
        this.ToCal = this.$route.params.ToCal;
        this.getMstCode();
        this.getUsers();
        this.getCustomInfo();
        this.getWarehouse();
        this.getPlanDetails();
      } finally {
        this.loadingCounter = 0;
      }
    },
    // 計画登録ボタン押下**登録しますかのアラート**
    planApproval() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-CNT-006_001_E");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },
    // 戻るボタン押下
    backPage() {
      let approvalFlg = this.inputList.every((val) => {
        return val.approvalFlg == "1";
      });
      if (approvalFlg) {
        this.updateOk();
      } else {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
      }
    },
    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 棚卸計画一覧に戻る場合
      if (this.backpage == "CountAchieveApproval") {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CNT_003,
          params: {
            returnParam: this.coutPlanListParameta, // 検索条件
            suppliersSelected: this.clientSid, //取引先
            person: this.manager, //担当者
            warehouse: this.warehouse, //倉庫
            location: this.location, //ロケグループ
            countImplementationDateFrom: dateTimeHelper.convertUTC(this.From), //棚卸実施日From
            countImplementationDateTo: dateTimeHelper.convertUTC(this.To), //棚卸実施日To
            From: this.From,
            FromCal: this.FromCal,
            To: this.To,
            ToCal: this.ToCal,
          },
        });
      }
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    // 完了フラグがひとつでもなければ承認ボタンを非活性にしダイアログを表示する
    checkValidFlg() {
      for (var t = 0; t < this.inputList.length; t++) {
        if (this.inputList[t].stockCheckOrderCompleteFlg == "0") {
          this.disableEdit = true;
          this.completeDialogMsg = true;
          this.completeMessage = messsageUtil.getMessage("P-CNT-006_003_C");
        }
      }
    },
    // すべて承認済みあれば承認ボタンを非活性にしダイアログを表示する
    checkValidApprovalFlg() {
      let approvalFlg = this.inputList.every((val) => {
        return val.approvalFlg == "1";
      });
      if (!this.controlFlg && approvalFlg) {
        this.disableEdit = true;
        this.completeDialogMsg = true;
        this.completeMessage = messsageUtil.getMessage("P-CNT-006_004_C");
      }
    },
    // 完了フラグダイアログを閉じる
    okcopy() {
      this.completeDialogMsg = false;
    },
    // 棚卸実績登録チェックフラグ変換
    confirmCheckDialog() {
      this.confirmCheckDialogFlg = false;
    },
    // 棚卸実績API
    getPlanDetails() {
      this.totalCount = 0;
      this.inputList = [];
      if (this.optionsSelect == null || this.optionsSelect == "") {
        // 処理なし
      } else {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const config = this.$httpClient.createGetApiRequestConfig();

        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        config.params.stockCheckDiv = this.inventoryMethodSelected;
        config.params.date = this.date;
        config.params.warehouseSid = this.warehouseSelected;
        config.params.stockCheckNo = this.stockNo;
        config.params.clientSid = this.clientSid;
        // 追加条件
        config.params.stockConditon = this.optionsSelect;

        return new Promise((resolve, reject) => {
          this.$httpClient
            .secureGet(appConfig.API_URL.BIZ_RETURNAPPROVAL, config)
            .then((response) => {
              // console.debug("getPlanDetails() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                this.totalCount = jsonData.resIdv.stockCheckAchievement.length;
                const list = [];
                jsonData.resIdv.stockCheckAchievement.forEach((row) => {
                  let lotObj = commonUtil.convertLotNo(row.lotNo);
                  list.push({
                    No: "", //明細No
                    stockCheckSid: row.stockCheckSid, //棚卸指示SID
                    stockResultSid: row.stockResultSid, //棚卸結果SID
                    integrateFlg: row.integrateFlg, // 統合判定Flg
                    countQty:
                      // 指示が完了していないかつ実績数0ならば空欄、それ以外は実績数を表示
                      row.stockCheckOrderCompleteFlg == "0" &&
                      row.isResult == "0" &&
                      Number(row.takenStock) == 0
                        ? ""
                        : commonUtil.formatToCurrency(Number(row.takenStock)), // 棚卸数
                    dataStock: commonUtil.formatToCurrency(Number(row.dataStock)), //在庫数
                    stockQty: commonUtil.formatToCurrency(Number(row.dataStock)), //在庫数
                    totalcount: commonUtil.formatToCurrency(Number(row.takenStock - row.dataStock)),
                    totalcheck: "",
                    locationGroup: row.locationGroup, //ロケーショングループ
                    locationSid: row.locationSid, //ロケーションSID
                    location: row.locationNo, //ロケーションNo
                    itemStandardSid: row.itemStandardSid, //商品標準SID
                    itemCd: row.itemCd.substring(0, 50).trim(), //品番
                    itemCdShow: row.itemCd, //品番
                    incidental: row.itemCd.slice(-50).trim(), //付帯品番
                    lotNoShow: row.lotNo, //ロット
                    lotNo: lotObj.lot, // ロット、S/N
                    dueDate: lotObj.dueDate, // 期限日、付属キー１、付属キー２
                    userSid: row.achievedUserSid,
                    popDestinationNm: row.achievedUserName, //担当者
                    stockCheckOrderCompleteFlg: row.stockCheckOrderCompleteFlg, //完了フラグ
                    approvalFlg: row.approvalFlg,
                    remarks: row.remarks,
                    difference: commonUtil.formatToCurrency(Number(row.takenStock - row.dataStock)), // 差異
                    colorFlg: false, // カラーフラグ
                    person: row.achievedUserSid ? row.achievedUserSid : row.plannedUserSid, // 作業者
                    stockCheckResultUpdateDatetime: row.stockCheckResultUpdateDatetime, // 棚卸結果更新日時
                  });
                });
                // 差異列：文字を色付け
                for (var i = 0; i < list.length; i++) {
                  // 比較のためdifferenceのコンマ除去
                  if (list[i].difference.replaceAll(",", "") < 0) {
                    list[i].colorFlg = true;
                  } else {
                    list[i].colorFlg = false;
                  }
                }
                this.inputList = list;
                // 棚卸実績登録チェックメッセージを最初一回のみ
                if (this.confirmCheckDialogFlg != false) {
                  this.checkValidFlg();
                  this.confirmCheckDialog();
                  this.checkValidApprovalFlg();
                }
                this.controlFlg = true;
                // 連番
                for (var n = 0; n < this.inputList.length; n++) {
                  this.inputList[n].No = n + 1;
                }
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              }
              this.$nextTick(() => {
                this.loadingCounter = 0;
              });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              // this.loadingCounter = 0;
            });
        });
      }
    },
    postApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      //★必須パラメータ
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CNT_006;
      var stockunCheckApproval = [];

      for (var n = 0; n < this.inputList.length; n++) {
        stockunCheckApproval.push({
          stockResultSid: this.inputList[n].stockResultSid, //棚卸結果SID
          stockCheckSid: this.inputList[n].stockCheckSid, //棚卸指示SID
          integrateFlg: this.inputList[n].integrateFlg, //統合判定Flg
          itemStandardSid: this.inputList[n].itemStandardSid, //商品標準SID
          itemCd: this.inputList[n].itemCd, //品番
          locationGroup: this.inputList[n].locationGroup, //ロケーショングループ
          locationSid: this.inputList[n].locationSid, //ロケーションSID
          lotNo: this.inputList[n].lotNoShow, //ロット
          userSid: this.inputList[n].userSid, //ユーザSID
          stockCheckResultUpdateDatetime: this.inputList[n].stockCheckResultUpdateDatetime, //棚卸結果更新日時
          remarks: this.inputList[n].remarks, //備考欄
        });
      }

      body.reqIdv.stockCheckApproval = stockunCheckApproval.slice();

      body.reqIdv.opeDispName = i18n.tc("label.lbl_countAchieveApperovalpc");
      body.reqIdv.stockCheckNo = this.stockNo; //棚卸指示No
      body.reqIdv.stockCheckDiv = this.inventoryMethodSelected; //棚卸方法区分
      body.reqIdv.date = this.date; //棚卸実施日
      body.reqIdv.warehouseSid = this.warehouseSelected; //倉庫SID
      body.reqIdv.stockCondition = "01"; //追加条件
      body.reqIdv.clientSid = this.clientSid; //取引先SID

      // console.debug("postApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.CNT_APPROVALACHIEVE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("postApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-CNT-006_002_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.cntapprovalFlg = true;
              this.infoDialog.firstPageFlag = false;

              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 取引先
    getCustomInfo() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );

      Promise.all([clientList])
        .then((result) => {
          this.supplierList = result[0];
          for (let i = 0; i < this.supplierList.length; i++) {
            if (this.suppliersSelected == this.supplierList[i].value) {
              this.suppliersSelectedName = this.supplierList[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          // this.loadingCounter = 0;
        });
    },
    // 倉庫プルダウン
    getWarehouse() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.searchCategory = "0";
      config.params.isMst = "0";

      // console.debug("getWarehouse() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_WAREHOUSE, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                });
              });

              // 画面の初期値を設定します。
              this.warehouseList = list;

              // 前画面から受け取った倉庫をセット
              for (let i = 0; i < this.warehouseList.length; i++) {
                if (this.warehouseSelected == this.warehouseList[i].value) {
                  this.warehouseSelectedName = this.warehouseList[i].text;
                }
              }
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    // 棚卸方式プルダウン
    getMstCode() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      const methodList = getParameter.getCodeMst(appConfig.CODETYPE.INVENTORY_MET);
      // 追加条件
      const optionList = getParameter.getCodeMst(appConfig.CODETYPE.OPTION_TYPE);

      Promise.all([methodList, optionList])
        .then((result) => {
          this.inventoryMethodList = result[0];
          // 追加条件リストを取得
          this.optionsList = result[1];
          // 追加条件の初期表示設定
          this.optionsSelect = this.optionsList[0].value;

          // 前画面から受け取った棚卸方式をセット
          for (let i = 0; i < this.inventoryMethodList.length; i++) {
            if (this.inventoryMethodSelected == this.inventoryMethodList[i].value) {
              this.inventoryMethodSelectedName = this.inventoryMethodList[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          // this.loadingCounter = 0;
        });
    },
    // 担当者プルダウン
    getUsers() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // ★必須パラメータ
      config.params.searchCategoryDiv = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getUsers() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                  name: row.userName,
                  userId: row.userId, //ユーザーID
                });
              });
              this.userList = list;
              // 取得したユーザーリストの中から、明細のユーザーSidと一致するものを探す
              for (var i = 0; i < this.inputList.length; i++) {
                for (var ii = 0; ii < this.userList.length; ii++) {
                  if (this.inputList[i].userSid == this.userList[ii].value) {
                    // inputListの担当者（person）に一致したユーザーSidを入れる
                    this.inputList[i].person = this.userList[ii].value;
                  }
                }
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    // 棚卸カンマ区切り処理
    commaCountQty(val, index) {
      var comma = commonUtil.zen2han(val.countQty).replaceAll(",", "");
      comma = comma.replace(/[^0-9]/gi, "");
      if (comma.length > 9) {
        comma = comma.substring(0, 9);
      }
      if (!isNaN(Number(comma))) {
        this.inputList[index].countQty = commonUtil.formatToCurrency(Number(comma));
      }
    },

    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    // 在庫数セット処理
    setStockQty(val, index) {
      // 商品リストの中から選択された品番と一致するものを割り出し
      for (var i = 0; i < this.itemList.length; i++) {
        if (this.inputList[index].itemCd == this.itemList[i].value) {
          // 在庫数に反映させる
          this.inputList[index].stockQty = this.itemList[i].quantity;
        }
      }
    },
    // 削除ボタン
    deleteItem(item) {
      // 指示SIDがある場合（既に登録済みのもの）
      if (this.inputList[0].stockCheckSid) {
        this.editedIndex = this.inputList.indexOf(item);
        // 削除Flgを１にしてポストする必要があるためdataとして持っておく
        this.inputList[this.editedIndex].deleteFlg = "1";
        this.deleteList[this.editedIndex] = this.inputList[this.editedIndex];
        // 画面上からは明細削除
        this.inputList.splice(this.editedIndex, 1);
      } else {
        this.editedIndex = this.inputList.indexOf(item);
        this.inputList.splice(this.editedIndex, 1);
      }
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    inventoryDateCal(val) {
      this.inventoryDate = this.formatDate(val);
    },
    optionsSelect(newValue) {
      // 追加条件
      if (newValue) {
        this.getPlanDetails();
      } else {
        this.getPlanDetails();
      }
    },
    sortOptions: {
      handler() {
        let replaceTarget = this.inputList.filter((element) => {
          return element.difference.includes(",") == true;
        });
        this.$nextTick(() => {
          replaceTarget.forEach((row) => {
            row.colorFlg = parseInt(row.difference.replaceAll(",", "")) < 0;
          });
        });
      },
      deep: true,
    },
    // 日付が変更されたら指示Noの末尾変更:営業所Cd-取引先Cd-日付
    inventoryDate(val) {
      // 棚卸日を編集
      var str = val;
      // ハイフン・時間カット
      str = str.split("/").join("").slice(0, 8);
      // 指示Noにセット
      this.inventoryPlanNo = `${this.officeCd}-${this.officeCd}-${str}`;
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.link {
  text-decoration: none;
}
</style>
